export function getIsMobile(callback) {
    const handleResize = () => {
      const width = window.innerWidth;
      callback(width<1025); // Appelez le callback pour mettre à jour l'état dans React
    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }
  export function getIsTablet(callback) {
    const handleResize = () => {
      const width = window.innerWidth;
      callback(width<1025 &&width>640 ); // Appelez le callback pour mettre à jour l'état dans React
    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }