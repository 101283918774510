import { getIsMobile } from '../../../../function/isMobile';
import { useEffect, useState } from 'react';

export default function Step({ element }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth);
    const [currentStep, setCurrentStep] = useState(0);

    function changeStep(i) {
        setCurrentStep(i);
        // let myElement = document.getElementById('step-' + i);
        if (isMobile) {
            let topPos = i > 0 ? (window.innerWidth - 40) * i : 0;
            document.getElementById('stepDescription').scrollLeft = topPos;
        } else {
            let topPos = i > 0 ? 495 * i : 0;
            document.getElementById('stepDescription').scrollTop = topPos;
        }
    }
    function nextStep(){
        changeStep(currentStep+1);
    }
    function prevStep(){
        changeStep(currentStep-1);
    }

    useEffect(() => {
        const cleanup = getIsMobile(setIsMobile);

        // Nettoyage : retirez l'écouteur d'événements lorsque le composant est démonté
        return cleanup;
    }, []);

    return <div className="w-full flex justify-center py-20">
        <section className='lg:w-4/5 w-full flex flex-col justify-center items-center gap-10'>
            <h2 className="lg:w-full w-11/12">{element.title}</h2>
            <div className='w-full flex justify-center '>
                <section className='lg:w-11/12 w-full inline-flex lg:flex-row flex-col items-center sm:h-[563px] min-h-[575px] bg-darkBlueGalaxy lg:rounded-lg lg:p-10 p-5 pb-20 relative'>
                    <div className={`lg:w-[35%] lg:h-full w-full h-[10%] flex lg:flex-col flex-row relative`}>
                        {element && element.etape.map((step, i) => {
                            return (
                                <button onClick={() => {
                                    changeStep(i)
                                }} key={i}
                                    className='lg:w-full w-1/5 h-full items-center inline-flex lg:h-1/5 group'>
                                    <div className='w-2/3 flex-col justify-center text-left lg:flex hidden'>
                                        <p className='leading-none opacity-50 font-josefinSansBold'>Etape {step.orderVignette}</p>
                                        <h3 className={`${currentStep == i ? "opacity-100" : currentStep > i ? "opacity-75" : "opacity-50 group-hover:opacity-75"}`}>{step.title}</h3>
                                    </div>
                                    <div className='lg:h-full lg:w-1/3 h-1/3 w-full flex justify-center items-center'>
                                        <div className={`min-w-7 min-h-7 z-10 flex transition-colors ease-in justify-center items-center rounded-full ${currentStep >= i ? i == element.etape.length - 1 ? "animation-stepBigClick bg-blueGalaxy w-8 h-8" : "bg-pinkGalaxy animation-stepClick" : "bg-middleBlueGalaxy group-hover:bg-lightBlueGalaxy"}`}>
                                            <img alt="" className={`w-5 h-5 transition-opacity ease-in ${currentStep >= i ? i == element.etape.length - 1 ? "opacity-100 animation-stepBigStarClick w-6 h-6" : "opacity-100 animation-stepStarClick" : "opacity-0 group-hover:opacity-30"}`} src='/picto_star_white.svg' />
                                        </div>
                                    </div>
                                </button>
                            )
                        })}
                        <div className='absolute flex justify-center items-center top-0 right-0 lg:h-full h-full w-full lg:w-1/3'>
                            <div className='bg-middleBlueGalaxy lg:w-2 lg:h-full h-2 w-full'>
                                <div className={`ease-in transition-all lg:w-full h-full shadowTimeline bg-pinkGalaxy shadow-pinkGalaxy ${currentStep == element.etape.length - 1 && "lg:bg-gradient-to-b bg-gradient-to-r from-pinkGalaxy to-blueGalaxy"} ${element.etape.length - 1 > 2 ? "from-80%" : "from-50%"} ${currentStep == 0 ? "lg:h-[10%] w-[10%]" : currentStep == 1 ? "lg:h-[30%] w-[30%]" : currentStep == 2 ? "lg:h-[50%] w-[50%]" : currentStep == 3 ? "lg:h-[70%] w-[70%]" : "lg:h-[90%] w-[90%]"}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className='absolute bottom-5 w-11/12 lg:hidden justify-between inline-flex'>
                        <button onClick={()=>prevStep()} disabled={currentStep==0} className='transition-opacity ease-in bg-blueGalaxy disabled:opacity-75 rounded-full h-8 w-8 flex justify-center items-center'><i class="fa-solid fa-chevron-left"></i></button>
                        <button onClick={()=>nextStep()} disabled={currentStep==element.etape.length-1} className='transition-opacity ease-in disabled:opacity-75 bg-pinkGalaxy rounded-full h-8 w-8 flex justify-center items-center'><i class="fa-solid fa-chevron-right"></i></button>
                    </div>
                    <div id='stepDescription' className='stepDescription w-full h-full overflow-hidden flex lg:flex-col flex-row scroll-smooth'>
                        {element && element.etape.map((step, i) => {
                            return <div className='flex flex-col min-w-full min-h-full lg:py-10 py-5 relative lg:items-start items-center' id={"step-" + i} key={i}>
                                <h3 className='opacity-50 inline-flex'><img alt="" className="w-5 h-5 mr-2" src='/picto_star_white.svg' />
                                    Etape {step.orderVignette}</h3>
                                <h2>{step.title}</h2>

                                <p className='lg:w-2/3 sm:w-2/3 h-1/2 w-full mt-5 lg:text-left text-center' dangerouslySetInnerHTML={{__html:step.description}}></p>
                                {step.img!=null && <img className='lg:absolute lg:bottom-0 lg:mt-0 mt-5 rounded-lg lg:right-0 max-w-[75%] max-h-[140px] lg:max-w-[35%] lg:max-h-[40%]' src={step.img} />}
                            </div>
                        })}
                    </div>
                </section>
            </div>
        </section>
    </div>
}