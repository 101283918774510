export default function ProfileDetails({ data }) {


    return (<>
        <section className="w-full h-[95vh] relative">
            <div className="flex items-end justify-center w-full h-full relative">
                <div className="text-left absolute bottom-14 lg:left-24 justify-center lg:w-1/2 w-11/12 h-1/4 z-30 rounded-lg flex flex-col">
                    <h2 className='titleSlider leading-none'>
                        {data.title}
                    </h2>
                    <h3>
                        {data.subtitle}
                    </h3>
                </div>
                <div className='absolute z-20 bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-darkBlueGalaxy/70 to-transparent'></div>
                <img alt="bannière" className="lg:block hidden self-center w-full h-[95vh] object-cover object-center absolute z-10" src={data.banner?? data.banner} />
                <img alt="bannière mobile" className="lg:hidden block self-center w-full h-[95vh] object-cover object-center absolute z-10" src={data.bannerMobile ?? data.bannerMobile} />
            </div>

        </section>
        <div className="w-full min-h-[400px] py-20 bg-darkBlueGalaxy justify-center flex">
            <div className="flex lg:flex-row flex-col w-5/6 items-center justify-between">
                <section className="lg:w-[45%] w-full flex flex-col gap-3">
                    <h2>{data.category == "art" ? "Le pourquoi l'art ?" : "Pourquoi coder ?"}</h2>
                    <p dangerouslySetInnerHTML={{ __html: data.reason }}></p>
                </section>
                <section className="inline-flex flex-col lg:w-1/3 w-full opacity-50 text-right gap-3">
                    <h3 className="inline-flex w-full justify-end"><img className='w-5 h-5 mr-2 mt-1' src='/picto_star_white.svg' />Anecdote</h3>
                    <p dangerouslySetInnerHTML={{ __html: data.anecdote }}></p>
                </section>
            </div>
        </div>
        <div className="w-full flex flex-col py-20 justify-start items-center">
            <section className="flex md:w-5/6 w-full flex-col items-center">
                <h2 className="md:w-full w-5/6">Mes créations</h2>
                {data.project?.length > 0 ?
                    <div className="pt-20 w-full flex flex-col items-center gap-20">
                        {data.project.map((project) => {
                            return <a href={data.category+'/' + project.slug} key={project.slug} className="bg-darkBlueGalaxy/70 group lg:hover:bg-middleBlueGalaxy/70 transition-colors ease-in  relative flex lg:flex-row flex-col-reverse lg:justify-center justify-start lg:gap-0 gap-7 lg:items-start items-center p-10 lg:rounded-lg lg:w-5/6 w-full xl:h-[500px] min-h-[500px]">
                                <div className="lg:w-2/3 w-full h-full flex flex-col items-start gap-3 lg:pr-20 lg:text-left text-center">
                                    <div className="inline-flex lg:self-start self-center opacity-70">
                                        <img className='w-4 h-4 mr-2' src='/picto_star_white.svg' />
                                        <h4>{project.date}</h4>
                                    </div>
                                    <h3 className="w-full">{project.name}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: project.brief }}></p>
                                    <div className="inline-flex flex-wrap lg:justify-start justify-center gap-4 lg:mt-auto mt-4 opacity-50 lg:self-start self-center">
                                        {project.label?.length > 0 && project.label.map(label => {
                                            return <p className="inline-flex"><img className='w-4 h-4 mr-2' src='/picto_star_white.svg' /> {label}</p>
                                            
                                        })}
                                    </div>
                                        <p className="addButton lg:hidden flex lg:mx-0 mx-auto mt-5">En savoir +</p>
                                </div>
                                <img src={project.layout ? project.layout:project.img} className="object-cover lg:group-hover:opacity-80 transition-opacity ease-in lg:w-1/3 w-full lg:h-full h-[400px]"></img>
                                <div className="lg:group-hover:opacity-100 lg:flex hidden opacity-0 transition-opacity ease-in bg-gradient-to-r from-blueGalaxy to-pinkGalaxy rounded-lg py-1 px-2 absolute -right-10 -bottom-3">
                                    <p><i className="fa-solid fa-arrow-right mr-2"></i>En savoir plus</p>
                                </div>
                            </a>
                        })}

                    </div>
                    : <p className="pt-20">Aucun projet pour le moment, revernez plus tard ( *︾▽︾)</p>}
            </section>
        </div>
    </>
    )
}