import { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function Modale({ children, show, requestClose, larger=false }) {

    return (
        // Use the `Transition` component at the root level
        <Transition show={show} as={Fragment}>
            <Dialog onClose={() => requestClose()}>
                {/*
          Use one Transition.Child to apply one transition to the backdrop...
        */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 z-50 backdrop-blur" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className='modale flex justify-center items-center w-screen h-screen fixed top-0 z-[63]'>
                        <Dialog.Panel className={`${larger?"lg:w-1/2":"lg:w-1/3"} w-11/12 flex justify-center items-center bg-[#2B2B47] rounded-lg`}>
                            {children}
                        </Dialog.Panel>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}
