import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules'
import { getIsMobile } from '../../../../function/isMobile';
import { useEffect, useState } from 'react';

export default function Slider({ element }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth);    
    
    useEffect(() => {
        const cleanup = getIsMobile(setIsMobile);
    
        // Nettoyage : retirez l'écouteur d'événements lorsque le composant est démonté
        return cleanup;
      }, []);

    return <div className="w-full flex justify-center py-20">
        <section className='lg:w-4/5 w-full flex flex-col items-center gap-10'>
            <h2 className="w-[85%]">{element.title}</h2>
            <div className='w-full flex justify-center relative'>

                <Swiper
                    slidesPerView={!isMobile?3:1.15}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    loop={element && (element.vignette.length>3 || element.vignette.length>2 && isMobile) ?true:false}
                    navigation={isMobile?false :true}
                    modules={[Pagination, Navigation]}
                    className={`w-11/12 xl:h-[473px] sm:h-[500px] h-[520px] !static sliderElement ${element.vignette.length<3 && !isMobile? "littleSliderElement": ""}`}>
                    {element && element.vignette.map((vignette, i) => {
                        return <SwiperSlide key={i} className="h-full">
                            <section className='bg-darkBlueGalaxy rounded-lg h-full w-full flex flex-col gap-3 items-center'>
                                <img className='bg-middleBlueGalaxy object-cover rounded-t-lg w-full h-[252px] text-ellipsis flex justify-center items-center' src={vignette.img} alt={"image de "+ vignette.title} />
                                <h3 className='w-4/5'>{vignette.title}</h3>
                                <p className='text-ellipsis w-4/5 h-[70px]'>{vignette.description}</p>
                                {vignette.label && vignette.label.length>0 &&
                                <div className='mt-auto mb-7 w-4/5 flex opacity-50 pt-5 gap-5 border-t-2 border-white'>
                                    {vignette.label.map((label, i) => {
                                        return <div className='flex flex-row items-center' key={i}>
                                            <img alt='' className='w-5 h-5 mr-2 mb-1' src='/picto_star_white.svg' />
                                            {label}
                                        </div>
                                    })}
                                </div>
                                }
                            </section>
                        </SwiperSlide>
                    })}

                </Swiper>
            </div>
        </section>

    </div>
}