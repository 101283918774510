import React from 'react';
// import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import { AdminHeader } from './Header/AdminHeader';
import { HeaderHomepage } from './Header/HeaderHomepage';
// import { Transition } from '@headlessui/react'


export function Header() {
    const sampleLocation = useLocation();
    // const [openAccueil, setOpenAccueil] = useState(true)

    window.addEventListener("scroll", function () {
        if (document.getElementById("header")) {

            let header = document.getElementById("header");
            let logo = document.getElementById("logo");
            let value = window.scrollY;
            if (value > 112 && window.innerWidth>1024) {
                header.classList.remove("lg:bg-black/20", "lg:h-28");
                header.classList.add("lg:bg-black/80", "lg:h-20");
                logo.classList.remove("lg:w-28");
                logo.classList.add("lg:w-24");
            }
            if (value < 112 && window.innerWidth>1024) {
                header.classList.remove("lg:bg-black/80", "lg:h-20");
                header.classList.add("lg:bg-black/20", "lg:h-28");
                logo.classList.remove("lg:w-24");
                logo.classList.add("lg:w-28");
            }
        }
    })

    return (
        <>
            {!sampleLocation.pathname.includes("admin") &&
                <HeaderHomepage location={sampleLocation}/>
            }
            {sampleLocation.pathname.includes("admin") &&
                    <AdminHeader location={sampleLocation}/>
            }

        </>

    );
}