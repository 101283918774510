export default function NotFound() {


    return <div className="w-full h-[100vh] pt-[150px] pb-20 flex flex-col items-center">
        <h2>Erreur 404</h2>
        <h3>Page non trouvé...</h3>
        <img className="lg:w-[20%] md:w-[50%] w-[75%] pt-5" src={"https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExbWNkdm4yMDJpaXd0bWh3NXVxc3h0MXJwYm9rYnZhMTcweW50OTAycCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/VwoJkTfZAUBSU/giphy.gif"}/>
    </div>

}
