import { Gallery, Item } from 'react-photoswipe-gallery';
import { getIsMobile } from '../../../../function/isMobile';
import { useEffect, useState } from 'react';

export default function Render({ element }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth);
    useEffect(() => {
        const cleanup = getIsMobile(setIsMobile);

        // Nettoyage : retirez l'écouteur d'événements lorsque le composant est démonté
        console.log(element.render);
        return cleanup;
    }, []);

    return <div className="w-full flex justify-center py-20">
        <section className='lg:w-4/5 w-11/12 flex flex-col justify-center items-center gap-10'>
            <h2 className="w-full">{element.title}</h2>
            <div className='w-full flex flex-col gap-20 justify-start items-center '>
                {element && element.render.map((render, i)=>{
                    if(render.description&&render.description.includes("youtu.be")){
                        let url= render.description.split("/");
                        return <iframe key={i} className='rounded-lg sm:w-[560px] sm:h-[315px]' src={"https://www.youtube.com/embed/"+url[3]} title="YouTube video player" referrerPolicy="strict-origin-when-cross-origin" frameBorder="0" allowFullScreen={true}></iframe>
                    }
                    else if(render.description&&render.description.includes("https://")){
                       return <div className='self-start rounded-lg p-5 bg-middleBlueGalaxy' key={i}><h4>Vous pouvez {element.render.length>1 ?"également":""} retrouver mon projet <a className='linkProject' target='_blank' href={render.description}>en cliquant ici</a></h4></div>
                    }
                    else if(render.img){
                        const imgObject = new Image();
                            imgObject.src =render.img;
                        return <Gallery>
                            <Item
                                original={render.img}
                                key={i}
                                width={imgObject.naturalWidth}
                                height={imgObject.naturalHeight}
                            >{({ ref, open }) => (
                                <img onClick={open} ref={ref} className='lg:max-w-[35%] max-w-[75%]' src={render.img} alt={render.title} />
                            )}
                            </Item>
                            <img /></Gallery>
                    }
                })}
            </div>
        </section>
    </div>
}