import { useState, Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useLocation } from 'react-router-dom'

export default function SideModale({ children, show, requestClose, larger=false }) {
    let location = useLocation();
    useEffect(()=>{
        requestClose();
    },[location.pathname])
    return (
        // Use the `Transition` component at the root level
        <Transition show={show} as={Fragment}>
            <Dialog onClose={() => requestClose()}>
                {/*
          Use one Transition.Child to apply one transition to the backdrop...
        */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 z-50 backdrop-blur" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="translate-x-[55vw]"
                    enterTo="translate-x-0"
                    leave="ease-in duration-200"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-[55vw]"
                >
                    <div className='modale flex justify-end items-center w-screen h-screen fixed top-0 z-[500]'>
                        <Dialog.Panel className={`flex justify-center items-start py-[80px] w-[180px] relative h-screen bg-darkBlueGalaxy`}>
                        <button className='absolute top-0 right-2 w-[70px] h-[80px] jusitfy-center items-center' onClick={()=>requestClose()}>
                            <i className="fa-solid fa-xmark text-3xl"></i>
                            </button>
                            {children}
                        </Dialog.Panel>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}
