import { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react'

export default function LoadingPage({ show = true }) {
    const [visible, setVisible] = useState(show);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        setTimeout(() => {
            setVisible(show);
            setTimeout(() => {
                document.body.style.overflow = "auto";
            }, 500)
        }, 1000)
    }, [show])

    return (
        <>
            <Transition
                className="transition-all flex flex-col justify-center items-center duration-[.2s] absolute top-0 right-0 z-[100] w-full h-full bg-black"
                show={visible}
                enterFrom="opacity-20 "
                enterTo="opacity-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-20">

                <div className='flex text-center lg:mb-0 mb-10 w-1/2 justify-center items-center z-20'>
                    <img id='logo' alt='logo Blackmoon' src='/Logo_lune_Noir.webp' className='w-52 h-auto transition-all  duration-[.3s] ease-in' />
                    <h1 className='font-varnaBold text-5xl ml-3 lg:inline hidden'>Remy Blackmoon</h1>
                </div>
                <svg id="svg-spinner" className='animate-spin' width="80" height="80" viewBox="0 0 48 48">
                    <circle cx="24" cy="4" r="4" fill="#CCCCff" />
                    <circle cx="12.19" cy="7.86" r="3.7" fill="#CCCCff" />
                    <circle cx="5.02" cy="17.68" r="3.4" fill="#BBBBff" />
                    <circle cx="5.02" cy="30.32" r="3.1" fill="#aaaaFF" />
                    <circle cx="12.19" cy="40.14" r="2.8" fill="#aaaaFF" />
                    <circle cx="24" cy="44" r="2.5" fill="#9999FF" />
                    <circle cx="35.81" cy="40.14" r="2.2" fill="#9999FF" />
                    <circle cx="42.98" cy="30.32" r="1.9" fill="#9999FF" />
                    <circle cx="42.98" cy="17.68" r="1.6" fill="#9999FF" />
                    <circle cx="35.81" cy="7.86" r="1.3" fill="#9999FF" />
                </svg>
            </Transition>





        </>
    )

}