import { useEffect, useState } from "react";
import LoadingPage from "../components/LoadingPage";
import Modale from "../components/Modale";


export default function Contact() {
    const [profile, setProfile] = useState(null)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isSubmited, setIsSubmited] = useState(false);
    const [gif, setGif] = useState(false);
    const [gifCount, setGifCount] = useState(0);
    useEffect(() => {
        try {
            fetch(process.env.REACT_APP_API_URL+"/getProfile")
                .then(response => response.json())
                .then((result) => {
                    if (!result.error) {
                        setProfile(result);
                        setLoading(false);
                    }
                })

            // Mise à jour du state avec les données chargées
        } catch (error) {
            console.error('Erreur lors du chargement des données :', error);
            // TO DO si pas de data, mettre une page prédef, et un bouton pour envoyé un mail pour dire que c'est la merde !
        }
    }, [])
    function validateEmail(email) {
        let res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return res.test(email) || email === "";
    }
    function checkEmail(email) {
        const emailInput = document.getElementById("emailInput");
        if (validateEmail(email) && email !== "") {
            emailInput.classList.remove("!border-red", "focus:!accent-redRevo");
            updateValue("email", email)
        }
        else {
            emailInput.classList.add("!border-red", "focus:!accent-redRevo");
            updateValue("email", null)

        }

    }

    function updateValue(type, value) {
        setData((prevData) => {
            let newData = { ...prevData };
            if (value !== null && value !== "") {
                newData[type] = value;
            } else {
                delete newData[type];
                setIsDisabled(true);
            }
            return newData;
        })
        if (data["email"] && data["identity"] && data["message"] && data["affiliation"] && data["subject"]) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true);
        }
    }

    function generateGif(){
        fetch("https://api.giphy.com/v1/gifs/random?api_key=AdYurNdSX6OSjPEp4VjJcb0IWqGEqnam&limit=1&tag=kamelotte&rating=g")
        .then(response => response.json())
        .then((res)=>{
            if(res.data.images){
                setGif(res.data.images.original.url);
                setGifCount(gifCount+1);
            }else{
                setGifCount(666);
            }
        })
    }

    function submit() {
        try {
            const headers = new Headers();
            headers.set('Origin', process.env.REACT_APP_URL);

            // Crée une instance de FormData
            let form = new FormData();
            form.append('identity', data["identity"]);
            form.append('message', data["message"]);
            form.append('email', data["email"]);
            form.append('affiliation', data["affiliation"]);
            form.append('subject', data["subject"]);
            form.append('date', new Date().toLocaleDateString("fr-FR", {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
            }));

            const requestOptions = {
                method: 'POST',
                body: form,
                headers: headers,
            };
            generateGif();

            fetch(process.env.REACT_APP_API_URL+'/mailer', requestOptions)
                .then(() => {
                    setIsSubmited(true);
                });
        } catch (error) {
            console.error('Erreur de l\'envoie du mail :', error);
        }
    }

    return <>
        <LoadingPage show={loading} />
        {profile &&
            <>
            <Modale show={isSubmited} requestClose={()=>setIsSubmited(false)}>
                <div className="w-full text-center flex flex-col min-h-[20vw] gap-5 items-center lg:py-10 lg:px-0 p-10">
                    <h2>
                        Email envoyé avec succès !
                        </h2>
                        <p>Il sera lu dans les plus bref délai <br/>( •̀ ω •́ )✧</p>
                        <img className="lg:max-w-[80%] max-w-full lg:max-h-[100vh] max-h-[50vh]" src={gif!=false && gif}/>
                        {gifCount<=2&&
                        <button onClick={()=>{generateGif(); setGifCount(gifCount+1);}} className="addButton">regénérer un gif !</button>}
                    </div>
            </Modale>
                <div className="w-full min-h-[400px] pb-20 pt-[192px] bg-darkBlueGalaxy justify-center flex">
                    <div className="flex lg:flex-row flex-col w-5/6 items-center justify-between">
                        <section className="lg:w-[45%] w-full flex flex-col gap-3">
                            <h2>Envie de parler ?</h2>
                            <p dangerouslySetInnerHTML={{ __html: profile.contactIntroduction }}></p>
                            <a target="_blank" href={process.env.REACT_APP_API_URL+'/uploads/CV_LyviaGuerre-Genton_DéveloppeuseWeb.pdf'} className="addButton w-20">Mon CV</a>
                        </section>
                        <section className="inline-flex flex-col lg:w-1/3 w-full opacity-50 text-right gap-3">
                            <h3 className="inline-flex w-full justify-end"><img className='w-5 h-5 mr-2 mt-1' src='/picto_star_white.svg' />Anecdote</h3>
                            <p dangerouslySetInnerHTML={{ __html: profile.anecdote }}></p>
                        </section>
                    </div>
                </div>
                <div className="w-full flex justify-center py-20">
                    <section className='lg:w-4/5 w-full flex flex-col items-center justify-center gap-10'>
                        <div className="lg:p-10 py-10 px-5 lg:w-1/2 w-[85%] flex flex-col bg-darkBlueGalaxy items-center rounded-lg gap-5">
                            <h3 className="w-full lg:w-11/12">J'ai besoin de quelques informations... :3</h3>
                            <div className="inline-flex lg:flex-row flex-col gap-2 lg:w-11/12 w-full">
                                <input onChange={(value) => { updateValue("identity", value.target.value) }} id="identity" type="text" className="inputModale rounded-lg lg:w-1/2 w-full" placeholder="Jean Dupont" />
                                <input onChange={(value) => { checkEmail(value.target.value) }} id="emailInput" type="text" className="inputModale rounded-lg lg:w-1/2 w-full" placeholder="jean.dupont@exemple.fr" />
                            </div>
                            <input onChange={(value) => { updateValue("affiliation", value.target.value) }} id="affiliation" type="text" className="inputModale rounded-lg lg:w-11/12 w-full" placeholder="Développeur chez Example Agency" />
                            <h3 className="w-full lg:w-11/12 mt-5">Et de votre message !</h3>
                            <input onChange={(value) => { updateValue("subject", value.target.value) }} id="subject" type="text" className="inputModale rounded-lg lg:w-11/12 w-full" placeholder="Objet : Demande de renseignements" />
                            <textarea onChange={(value) => { updateValue("message", value.target.value) }} id="message" className="bg-lightBlueGalaxy/10 border-[3px] rounded-lg lg:w-11/12 w-full min-h-[200px] !resize-y" placeholder="Bonjour, je vous contacte car..."></textarea>
                            <button disabled={isDisabled} onClick={() => { submit() }} className="addButton">Envoyer un mail</button>
                        </div>
                    </section>
                </div>
            </>
        }
    </>
}