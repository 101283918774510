import React from 'react';
// import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import { AdminHeader } from './Header/AdminHeader';
import { HeaderHomepage } from './Header/HeaderHomepage';
// import { Transition } from '@headlessui/react'


export function Footer() {
    const sampleLocation = useLocation();

    return (
        <>
            {!sampleLocation.pathname.includes("admin") ?
                <div className='w-full mt-10 pt-[50px] relative justify-center flex flex-col items-center'>
                    <section className='lg:absolute top-0 lg:w-[800px] lg:h-[100px] lg:py-0 py-10 w-full circleGradient shadowColor lg:rounded-lg inline-flex lg:flex-row flex-col items-center justify-between px-5 lg:gap-0 gap-3'>
                        <div className='h-full flex flex-col justify-center lg:items-start items-center lg:gap-0 gap-2'>
                            <div className=' inline-flex items-center'>
                                <img className='w-5 h-5 mb-1 mr-2' src='/picto_star_white.svg' />
                                <h3>Mon profil vous intéresse ?</h3>
                            </div>
                            <p className='text-lightBlueGalaxy/50'><i className="fa-solid fa-check mr-1"></i>Web<span><i className="fa-solid fa-check ml-2 mr-1"></i>Créativité</span><span><i className="fa-solid fa-check ml-2 mr-1"></i>Art</span></p>
                        </div>
                        <div className='inline-flex gap-5'>
                            <a className='darkButton h-1/3' href='/contact'>Contact</a>
                            <a target="_blank" href={process.env.REACT_APP_API_URL+'/uploads/CV_LyviaGuerre-Genton_DéveloppeuseWeb.pdf'} className='addButton h-1/3'>Mon CV</a>
                        </div>

                    </section>
                    <section className='bg-darkBlueGalaxy w-full lg:h-[350px] flex flex-col items-center lg:gap-0 gap-5 lg:pb-0 py-10'>
                        <div className='w-5/6 h-3/4 inline-flex lg:flex-row flex-col items-center justify-between lg:gap-0 gap-5'>
                            <div className='flex flex-col lg:items-start items-center gap-5'>
                                <a href="/" className='flex lg:flex-row flex-col items-center z-20 gap-3'><img id='logo' alt='logo Blackmoon' src='/Logo_lune_Noir.webp' className='w-28 h-auto transition-all  duration-[.3s] ease-in' /><h1>Remy Blackmoon</h1></a>
                                <div className='inline-flex gap-5 items-center'>
                                    <a title='Mon profil linkedin' target='_blank' href='https://www.linkedin.com/in/lyvia-guerre-genton/' className='group cursor-pointer'><i className="fa-brands fa-linkedin text-3xl group-hover:text-blueGalaxy"></i></a>
                                    <a title='Ma chaine youtube' target='_blank' href='https://www.youtube.com/channel/UCiwh3Z-GQG2NGDdvUR0cXkw' className='group cursor-pointer'><i className="fa-brands fa-youtube text-3xl group-hover:text-blueGalaxy"></i></a>
                                    <a title='Mon profil instagram' target='_blank' href='https://www.instagram.com/remy_blackmoon/' className='group cursor-pointer'><i className="fa-brands fa-instagram text-3xl group-hover:text-blueGalaxy"></i></a>
                                    <a title='Mon profil MangaDraft' target='_blank' href='https://www.mangadraft.com/user/remy-blackmoon' className='group cursor-pointer'><i className="fa-solid fa-pen text-2xl group-hover:text-blueGalaxy"></i></a>
                                </div>
                            </div>
                            <div className='inline-flex lg:flex-row flex-col lg:justify-end items-center gap-14'>
                                <div className='flex flex-col lg:items-start items-center'>
                                    <h3>Artistiquement curieux ?</h3>
                                    <a href="/art" className='underline'><p><i className="fa-solid fa-arrow-right mr-2"></i>Toutes mes créations</p></a>
                                </div>
                                <div className='flex flex-col lg:items-start items-center'>
                                    <h3>Intéressez par le dev web ?</h3>
                                    <a href="/web" className='underline'><p><i className="fa-solid fa-arrow-right mr-2"></i>Tous mes projets</p></a>
                                </div>
                            </div>
                        </div>
                        <div className='h-1/4 lg:pt-0 pt-5 w-5/6 border-t border-white/50 flex justify-center items-end flex-col opacity-50'>
                            <p>Conçu et réalisé</p>
                            <p>par Lyvia Guerre-Genton - 2024</p>
                        </div>
                    </section>
                </div>



                : <></>
            }
        </>

    );
}