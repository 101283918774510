import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import LoadingPage from '../components/LoadingPage';
import ParcoursTimeline from './ParcoursTimeline';

export default function Home() {
    const [profile, setProfile] = useState(null)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        // document.body.style.overflow = "hidden";

        try {
            fetch(process.env.REACT_APP_API_URL+"/getProfile")
                .then(response => response.json())
                .then((result) => {
                    if (!result.error) {
                        setProfile(result);
                        setLoading(false);
                    }
                })

            // Mise à jour du state avec les données chargées
        } catch (error) {
            console.error('Erreur lors du chargement des données :', error);
            // TO DO si pas de data, mettre une page prédef, et un bouton pour envoyé un mail pour dire que c'est la merde !
            }
    }, []);

    return (
        <>
            <LoadingPage show={loading} />
            {profile &&
                <>
                    <Swiper pagination={{ clickable: true }}
                        // modules={[Pagination]}
                        modules={[Pagination, Autoplay]}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: false,
                        }}

                        loop={true}
                        className="swiperBanner w-full h-[95vh]">

                        {profile && profile.banner.map((banner) => {
                            return (
                                <SwiperSlide key={banner.id} className="">
                                    <div className="flex items-end justify-center w-full h-full relative">
                                        <div className="text-left absolute bottom-14 lg:left-24 justify-center lg:w-1/2 w-11/12 h-1/4 z-30 rounded-lg flex flex-col">
                                            <h2 className='titleSlider leading-none lg:truncate '>
                                                {banner.title}
                                            </h2>
                                            <h3>
                                                {banner.subtitle}
                                            </h3>
                                        </div>
                                        <div className='absolute z-20 bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-darkBlueGalaxy/70 to-transparent'></div>
                                        <img alt="bannière" className="lg:block hidden self-center w-full h-[95vh] object-cover object-center absolute z-10" src={banner.banner?? banner.banner}/>
                                        <img alt="bannière mobile" className="lg:hidden block self-center w-full h-[95vh] object-cover object-center absolute z-10" src={banner.bannerMobile ?? banner.bannerMobile}/>
                                    </div>

                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <section className='w-full flex justify-center items-center py-20 bg-darkBlueGalaxy'>
                        <div className='lg:w-5/6 w-[85%] inline-flex md:flex-row flex-col-reverse items-center md:justify-between gap-10'>
                            <section className='md:w-1/2 w-full'>
                                <h3 className='text-3xl font-josefinSansBold pb-5'>Qui suis-je ?</h3>
                                <p dangerouslySetInnerHTML={{ __html: profile && profile.description }}></p>
                                {/* <button className='addButton mt-5' onClick={() => {
                                    // window.open(profile.CVpath, '_blank')
                                    console.log("cv")
                                }}>Mon CV</button> */}
                                <a target="_blank" href={process.env.REACT_APP_API_URL+'/uploads/CV_LyviaGuerre-Genton_DéveloppeuseWeb.pdf'} className='addButton mt-5 flex w-20'>Mon CV</a>
                            </section>
                            <section className='md:w-[250px] md:h-[250px] w-[150px] h-[150px] relative'>
                                <img src={profile ? profile.img : ""} alt="photo de profile" className='md:w-[250px] md:h-[250px] w-[150px] h-[150px] object-cover rounded-full bg-blueGalaxy' />
                                <p className="absolute top-5 md:-left-5 -left-8 px-2 py-1 bg-[#3D82F6]/20 backdrop-blur rounded-xl">{profile.status}</p>
                            </section>
                        </div>

                    </section>

                    <ParcoursTimeline parcours={profile.parcours} />
                    <div className='lg:pt-48 lg:pb-20 pt-20 relative flex flex-col justify-center items-center text-center'>
                        <section className='bg-blueGalaxy w-full lg:pb-36 lg:pt-24 py-10 flex justify-center lg:absolute top-0'>
                            <h2 className='lg:w-full w-5/6'>Et mes compétences dans tout ça ?</h2>
                        </section>
                        <div className='z-20 relative lg:w-5/6 w-full py-10 lg:gap-0 gap-10 bg-darkBlueGalaxy inline-flex lg:flex-row flex-col lg:items-start items-center lg:rounded-lg '>
                            <section className={`lg:w-1/3 w-5/6 transition-colors duration-200 ease-in rounded-l-lg lg:border-r-2 border-r-0 lg:border-b-0 border-b-2 flex flex-col justify-center items-center border-blueGalaxy/20 gap-5 lg:py-0 py-10 lg:px-10 px-5`}>
                                <div className='flex flex-col justify-center gap-2'>
                                    <i className="fa-solid fa-code text-3xl"></i>
                                    <h2>Dev web</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: profile.details[1].skills[0] }}></p>
                                <div className='flex flex-col justify-center'>
                                    <p className='text-blueGalaxy'>Tous mes langages :</p>
                                    <p dangerouslySetInnerHTML={{ __html: profile.details[1].skills[1] }}></p>
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <p className='text-blueGalaxy'>Mes outils de dev à votre service :</p>
                                    <p dangerouslySetInnerHTML={{ __html: profile.details[1].skills[2] }}></p>
                                </div>
                            </section>
                            <section className={`lg:w-1/3 w-5/6 transition-colors duration-200 ease-in lg:border-r-2 border-r-0 lg:border-b-0 border-b-2 flex flex-col justify-center items-center border-blueGalaxy/20 gap-5 lg:py-0 py-10 lg:px-10 px-5`}>
                                <div className='flex flex-col justify-center gap-2'>
                                    <img src="/picto_star_white.svg" className="h-[50px]" />
                                    <h2>Mes skills</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: profile.skills[0] }}></p>
                                <div className='flex flex-col justify-center'>

                                    <p className='text-blueGalaxy'>Mes softs :</p>
                                    <p dangerouslySetInnerHTML={{ __html: profile.skills[1] }}></p>
                                </div>
                                <div className='flex flex-col justify-center'>

                                    <p className='text-blueGalaxy'>Et ceux un peu trop naturels :</p>
                                    <p dangerouslySetInnerHTML={{ __html: profile.skills[2] }}></p>
                                </div>

                            </section>
                            <section className={`lg:w-1/3 w-5/6 transition-colors duration-200 ease-in rounded-r-lg flex flex-col justify-center items-center gap-5 lg:py-0 py-10 lg:px-10 px-5`}>
                                <div className='flex flex-col justify-center gap-2'>
                                    <i className="fa-solid fa-layer-group text-3xl"></i>
                                    <h2>Art et designs</h2>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: profile.details[0].skills[0] }}></p>
                                <div className='flex flex-col justify-center'>

                                    <p className='text-blueGalaxy'>Ce que je préfère faire :</p>
                                    <p dangerouslySetInnerHTML={{ __html: profile.details[0].skills[1] }}></p>
                                </div>
                                <div className='flex flex-col justify-center'>

                                    <p className='text-blueGalaxy'>Et les outils qui vont avec :</p>
                                    <p dangerouslySetInnerHTML={{ __html: profile.details[0].skills[2] }}></p>
                                </div>
                            </section>
                        </div>
                    </div>

                </>
            }

        </>

    )
}