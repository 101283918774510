import React, { useState } from 'react';
// import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
import SideModale from '../components/SideModale';
// import { Transition } from '@headlessui/react'


export function HeaderHomepage({ location }) {
    const [showMenu, setShowMenu] = useState(false);
    return <>
        <nav id='header' className='transition-all duration-[.3s] ease-in fixed top-0 z-40 flex justify-center lg:bg-black/20 bg-black/20 backdrop-blur w-screen lg:h-28 h-20'>

            <div className='lg:w-11/12 w-full px-4 h-full inline-flex font-josefinSans justify-between relative'>
                <div className='text-center lg:w-1/2 flex justify-start items-center'>
                    <a href="/" className='flex items-center z-20 gap-3'><img id='logo' alt='logo Blackmoon' src='/Logo_lune_Noir.webp' className='lg:w-28 w-[77px] h-auto transition-all  duration-[.3s] ease-in' /><h1 className='lg:inline hidden'>Remy Blackmoon</h1></a>
                </div>
                <ul className='lg:flex hidden w-1/2 h-full text-center items-center justify-evenly font-josefinSansBold text-2xl z-30'>
                    <li className='text-center'>
                        <a href="/web"><h3>Web</h3></a>
                    </li>
                    <li className='text-center'>
                        <a href="/art"><h3>Art</h3></a>
                    </li>
                    <li className='text-center'>
                        <a href="/contact"><h3>Contact</h3></a>
                    </li>
                </ul>
                <button onClick={() => { setShowMenu(true) }} className='lg:hidden flex h-full px-2 justify-center items-center'>
                    <i className="fa-solid fa-bars text-4xl"></i>
                </button>
                <SideModale show={showMenu} requestClose={() => setShowMenu(false)}>
                    <ul className='w-full flex flex-col justify-start gap-5'>
                        <li className='text-right px-5'>
                            <a href="/"><h4 className={`${location.pathname=="/"?"bg-blueGalaxy/50":"hover:bg-blueGalaxy/20"} pl-2 pr-3 py-2 rounded-lg`}>Accueil <i className="fa-solid fa-house ml-5 !text-2xl"></i></h4></a>
                        </li>
                        <li className='text-right px-4'>
                            <a href="/web"><h4 className={`${location.pathname=="/web"?"bg-blueGalaxy/50":"hover:bg-blueGalaxy/20"} pl-2 pr-3 py-2 rounded-lg`}>Web <i className="fa-solid fa-code ml-5 !text-2xl"></i></h4></a>
                        </li>
                        <li className='text-right px-4'>
                            <a href="/art"><h4 className={`${location.pathname=="/art"?"bg-blueGalaxy/50":"hover:bg-blueGalaxy/20"} pl-2 pr-3 py-2 rounded-lg`}>Art <i className="fa-solid fa-pen ml-5 !text-2xl"></i></h4></a>
                        </li>
                        <li className='text-right px-4'>
                            <a href="/contact"><h4 className={`${location.pathname=="/contact"?"bg-blueGalaxy/50":"hover:bg-blueGalaxy/20"} pl-2 pr-3 py-2 rounded-lg`}>Contact <i className="fa-solid fa-address-book !text-2xl ml-4"></i></h4></a>
                        </li>
                    </ul>
                </SideModale>
            </div>
        </nav>
    </>

}