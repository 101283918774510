import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
// import "https://kit.fontawesome.com/e1d32033c2.js";
import { Base } from './Base';
// import HomeAdmin from './pages/admin/Home/HomeAdmin';
// import Admin from './pages/admin/Admin';
// import ArtAdmin from './pages/admin/Art/ArtAdmin';
// import WebAdmin from './pages/admin/Web/WebAdmin';
import Art from './pages/profileDetails/Art';
import Web from './pages/profileDetails/Web';
import Project from './pages/profileDetails/project/Project';
import Contact from './pages/Contact/Contact';
import NotFound from './pages/NotFound';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route element={<Base />}>
        <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />}></Route>
          <Route path="/web" element={<Web />}></Route>
          <Route path="/art" element={<Art />}></Route>
            <Route path="/art/:slug" element={<Project/>}></Route>
            <Route path="/web/:slug" element={<Project/>}></Route>
            <Route path="/contact" element={<Contact/>}></Route>

          {/* <Route path="/admin" element={<Admin />}>
            <Route path="/admin/" element={<HomeAdmin />}></Route>
            <Route path="/admin/art" element={<ArtAdmin />}></Route>
            <Route path="/admin/web" element={<WebAdmin />}></Route>
          </Route> */}
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>



);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
