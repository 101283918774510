import { useEffect, useState } from "react";


export default function ParcoursTimeline({ parcours }) {
    const [height, setHeight] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", function () {
            let content = document.getElementById('timelineBody');
            if(content){

                let timeline = document.getElementById('timeline');
                // let body = document.getElementById('contentHomepage');
                let positionContent = content.getBoundingClientRect();
                // ###offsetHeight : hauteur total - 
                // let newHeight= window.scrollY+positionContent.top+ ((window.scrollY+positionContent.top)-(window.innerHeight / 2)) ;
                if (positionContent.top < window.innerHeight && positionContent.bottom >= 0) {
                    let newHeight = content.offsetHeight - positionContent.bottom + (window.innerHeight / 2);
                    timeline.style.height = window.scrollY + positionContent.top
                    if (timeline.offsetHeight < content.offsetHeight || (timeline.offsetHeight > newHeight)) {
                        timeline.style.height = newHeight + "px";
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
    }, [height])
    return <section className='w-full flex justify-center'>
        <div className='md:w-5/6 w-full flex flex-col md:justify-start jusitfy-center items-center py-20 '>
            <div id="parcours" className='shadow-inner shadow-black flex flex-col items-center relative justify-center w-full'>
                <h2 className="md:w-full w-5/6 md:text-left text-center">Mon parcours</h2>
                <div className="w-full flex flex-col items-center relative justify-center md:gap-[300px] gap-[200px] py-20">
                {parcours && parcours.map((route) => {
                    return (<div key={route.id} className='md:w-5/6 w-full h-auto relative z-20 flex items-center'>
                        <section className='md:w-1/2 w-full md:pr-14'>
                        <div className="flex flex-col justify-start gap-2 md:px-4 px-5 py-5 md:bg-darkBlueGalaxy bg-black/50 backdrop-blur rounded-lg">
                            <div className="inline-flex flex-row items-center justify-between w-full opacity-70">
                                <div className="inline-flex"><img className='w-4 h-4 mb-1 mr-2 md:block hidden' src='/picto_star_white.svg' />
                                <h4>{route.category}</h4></div>
                                <h4 className="md:hidden flex">{route.date}</h4>
                            </div>
                            <h3>{route.title}</h3>
                            <p className="w-5/6" dangerouslySetInnerHTML={{ __html: route.description }}></p>
                            {route.link && route.link!=-1? <a className='linkProject' href={'/' + route.link}>Voir le projet</a> : <></>}
                        </div>
                        </section>
                        <section className='w-1/4 md:flex hidden items-center justify-center flex-col relative z-20'>
                            <div className="w-10 h-10 z-20 rounded-full bg-white border-[8px] border-black"></div>
                            <div className="absolute z-10 -left-2 inline-flex items-center opacity-30">
                                <img className='w-6 h-6 mr-2' src='/picto_star_white.svg' />
                                <div className="bg-blueGalaxy w-[95px] h-3 rounded-full"></div>
                            </div>
                        </section>
                        <section className='w-1/4 md:flex hidden items-center justify-start'>
                            <h3>{route.date}</h3>
                        </section>
                    </div>)
                })
                }
                </div>
                <div className='md:right-0 md:top-0 top-20 absolute md:justify-start justify-center flex md:h-full h-[95%] w-1/2'>
                    <div id="timelineBody" className='h-full w-3 md:ml-[20%] absolute bg-blueGalaxy/20'></div>
                    <div id="timeline"
                        className='shadowColor rounded-b-full w-3 md:ml-[20%] absolute bg-gradient-to-b from-blueGalaxy
             to-pinkGalaxy'></div>
                </div>
            </div>
        </div>

    </section>

}