import Masonry from 'react-masonry-css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import { useEffect, useState } from 'react';
import { getIsMobile, getIsTablet } from '../../../../function/isMobile';

export default function Mosaic({ element }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth);
    const [isTablet, setIsTablet] = useState(window.innerWidth);
    useEffect(() => {
        const cleanup = getIsMobile(setIsMobile);
        const cleanupBis = getIsTablet(setIsTablet);

        // Nettoyage : retirez l'écouteur d'événements lorsque le composant est démonté
        return cleanup, cleanupBis;
    }, []);

    return <div className="w-full flex justify-center py-20">
        <section className='lg:w-4/5 w-[85%] flex flex-col justify-center gap-10'>
            <h2 className="w-full">{element.title}</h2>
            <div className='w-full flex justify-center relative'>
                <Gallery>
                    <Masonry
                        breakpointCols={isTablet ? 2 : isMobile ? 1 : 3}
                        className="flex -ml-[10px] w-auto"
                        columnClassName="pl-[10px] bg-clip-padding"
                    >
                        {element.img && element.img.map((img, i) => {
                            const url = new URL(img.img)
                            let width = new URLSearchParams(url.search).get("width");
                            let height = new URLSearchParams(url.search).get("height");
                            return <Item
                                original={img.img}
                                key={i}
                                width={width}
                                height={height}
                            >{({ ref, open }) => (
                                <img onClick={open} ref={ref} className='mb-[10px] cursor-pointer' src={img.img} alt="" />
                            )}
                            </Item>
                        })}

                    </Masonry>
                </Gallery>

            </div>
        </section>
    </div>

}