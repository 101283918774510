import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import Slider from "./Elements/Slider";
import Print from "./Elements/Print";
import Mosaic from "./Elements/Mosaic";
import Step from "./Elements/Step";
import Render from "./Elements/Render";
import { useNavigate } from 'react-router-dom';

export default function Project() {
    const [projectData, setProjectData] = useState();
    const [loading, setLoading] = useState(true)
    const { slug } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        // document.body.style.overflow = "hidden";
        try {
            fetch(process.env.REACT_APP_API_URL+"/getProjectBySlug?slug=" + slug)
                .then(response => response.json())
                .then((result) => {
                    if (!result.error) {
                        setProjectData(result);
                        setLoading(false);
                    } else {
                        navigate("/404");
                    }
                })

            // Mise à jour du state avec les données chargées
        } catch (error) {
            console.error('Erreur lors du chargement des données :', error);
            // TO DO si pas de data, mettre une page prédef, et un bouton pour envoyé un mail pour dire que c'est la merde !
        }
    }, []);

    function adaptElementCategory(element, i) {
        switch (element.category) {
            case "slider":
                return <Slider key={i} element={element} />

            case "print":
                return <Print key={i} element={element} />

            case "mosaic":
                return <Mosaic key={i} element={element} />

            case "etape":
                return <Step key={i} element={element} />
            case "rendu":
                return <Render key={i} element={element} />

            default:
                break;
        }
    }

    return <>
        <LoadingPage show={loading} />
        {projectData && <>
            <section className="w-full h-[95vh] relative">
                <div className="flex items-end justify-center w-full h-full relative">
                    <div className="text-left absolute bottom-5 justify-center w-5/6 h-1/4 z-30 rounded-lg flex flex-col">
                        <h2 className='titleSlider leading-none  text-right'>
                            {projectData.name}
                        </h2>
                    </div>
                    <div className='absolute z-20 bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-darkBlueGalaxy/70 to-transparent'></div>
                    <img alt="bannière" className="self-center lg:block hidden w-full h-[95vh] object-cover object-center absolute z-10" src={projectData.banner ? projectData.banner : projectData.img}/>
                    <img alt="bannière mobile" className="self-center w-full lg:hidden block h-[95vh] object-cover object-center absolute z-10" src={projectData.bannerMobile ? projectData.bannerMobile : projectData.img}/>
                </div>

            </section>
            <div className="w-full min-h-[400px] py-20 bg-darkBlueGalaxy justify-center flex">
                <div className="flex lg:flex-row flex-col lg:w-5/6 w-[85%] items-center justify-between">
                    <section className="lg:w-[40%] w-full flex flex-col gap-3">
                        <h2>Création du projet</h2>
                        <p dangerouslySetInnerHTML={{ __html: projectData.description }}></p>
                    </section>
                    <section className="inline-flex flex-col lg:w-[25%] w-full opacity-50 text-right gap-3 lg:mt-0 mt-4">
                        <h3 className="inline-flex w-full justify-end"><img alt="" className='w-5 h-5 mr-2 mt-1' src='/picto_star_white.svg' />Anecdote</h3>
                        <p dangerouslySetInnerHTML={{ __html: projectData.anecdote }}></p>
                    </section>
                </div>
            </div>
            {projectData.element && projectData.element.length>0 ? projectData.element.map((element, i) => {

                return adaptElementCategory(element, i);
            })
                : <div className="w-full flex items-center justify-center py-20"><p className="pt-20">Le projet est vide pour l'instant, revenez plus tard ! ( *︾▽︾) </p></div>
            }
        </>
        }
    </>

}