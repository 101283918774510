import { useEffect, useState } from "react"
import ProfileDetails from "./ProfileDetails"
import LoadingPage from "../components/LoadingPage";

export default function Art() {
    const [artData, setArtData]= useState();
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        // document.body.style.overflow = "hidden";

        try {
            fetch(process.env.REACT_APP_API_URL+"/getProfileDetails?category=art")
                .then(response => response.json())
                .then((result) => {
                    if (!result.error) {
                        setArtData(result);
                        setLoading(false);
                    }
                })

            // Mise à jour du state avec les données chargées
        } catch (error) {
            console.error('Erreur lors du chargement des données :', error);
            // TO DO si pas de data, mettre une page prédef, et un bouton pour envoyé un mail pour dire que c'est la merde !
            }
    }, []);

    return (<>
    <LoadingPage show={loading} />
    {artData &&
    <ProfileDetails data={artData}/>  
}    
    </>
    )}