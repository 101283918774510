import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import { getIsMobile } from '../../../../function/isMobile';
import { useEffect, useState } from 'react';

export default function Print({ element }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth);    
    useEffect(() => {
        const cleanup = getIsMobile(setIsMobile);
    
        // Nettoyage : retirez l'écouteur d'événements lorsque le composant est démonté
        return cleanup;
      }, []);

    return <div className="w-full flex justify-center py-20">
        <section className='lg:w-4/5 w-full flex flex-col items-center gap-10'>
            <div className='w-[85%]'>

            <h2 className="w-full">{element.title}</h2>
            {element.reverse&&
            <h4 className='w-full'><i class="fa-solid fa-triangle-exclamation mr-2 text-red"></i>Lecture de droite à gauche</h4>}
            </div>
            <div className='w-full flex justify-center relative'>
                <Gallery>
                    <Swiper
                        slidesPerView={isMobile?1.2:2}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        initialSlide={element.reverse ? element.print.length : 0}
                        navigation={isMobile?false:true}
                        modules={[Pagination, Navigation]}
                        className={`xl:w-[70%] w-11/12 !static sliderElement ${element.print.length < 2 ? "littleSliderElement" : ""}`}>

                        {element && element.print.map((print, i) => {
                            const url = new URL(print.img)
                            let width = new URLSearchParams(url.search).get("width");
                            let height = new URLSearchParams(url.search).get("height");
                            return <SwiperSlide key={i} className="h-full">
                                <Item
                                    original={print.img}
                                    key={i}
                                    width={width}
                                    height={height}
                                >{({ ref, open }) => (
                                    <img onClick={open} ref={ref} className='cursor-pointer' src={print.img} alt='' />
                                )}
                                </Item>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </Gallery>
            </div>
        </section>
    </div>

}