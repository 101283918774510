import React, { useEffect } from 'react';
// import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"
// import { Transition } from '@headlessui/react'


export function AdminHeader({location}) {
    

    return <>
        <nav className='fixed left-0 font-josefinSans bg-darkBlueGalaxy w-[165px] h-full z-[29] flex flex-col justify-start items-center'>
            <Link to="/" className='flex h-1/6 top-0 items-center z-20'>
                <img id='logo' alt='logo Blackmoon' src='/Logo_lune_Noir.webp' className='w-28 transition-all duration-[.3s] ease-in' />
            </Link>
            <section className='flex flex-col mt-5 w-5/6 gap-7'>

                <Link to="/admin"
                    className={`cursor-pointer pt-4 pb-3 ${!location.pathname.includes("art") && !location.pathname.includes("web")?"bg-lightBlueGalaxy":"hover:bg-lightBlueGalaxy/70"} transition-colors duration-200 ease-in rounded-lg inline-flex items-center px-4`}><i className="fa-solid fa-house mr-4 text-xl mb-1"></i><h4>Accueil</h4>
                </Link>
                <Link to="/admin/art"
                    className={`cursor-pointer pt-4 pb-3 ${location.pathname.includes("art")?"bg-lightBlueGalaxy":"hover:bg-lightBlueGalaxy/70"} transition-colors duration-200 ease-in  rounded-lg inline-flex items-center px-4`}><i className="fa-solid fa-pen mr-4 text-xl "></i><h4>Art</h4>
                </Link>
                <Link to="/admin/web"
                    className={`cursor-pointer pt-4 pb-3 ${location.pathname.includes("web")?"bg-lightBlueGalaxy":"hover:bg-lightBlueGalaxy/70"} transition-colors duration-200 ease-in rounded-lg inline-flex items-center px-4`}><i className="fa-solid fa-code mr-4 text-xl"></i><h4>Web</h4>
                </Link>

            </section>

        </nav>

    </>
}